import React from "react";
import {
    Button,
    Grid, Loader, Textarea
} from "@mantine/core";
import {IconTrash} from "@tabler/icons-react";
import CsvFileInput from "../../../../components/CsvFileInput/CsvFileInput";

function SalesBulkMessagesForm(props) {
    const {form, loading} = props;

    const handleFileLoad = (csvData) => {
        // Here I can manipulate the phone numbers so they are in the correct format
        form.setFieldValue('csvData', csvData);
    };

    return (
        <div style={{width: "100%"}}>
            <Grid>
                <Grid.Col span={11}>
                    <CsvFileInput
                        onFileLoad={handleFileLoad}
                        value={form.values.csvFile}
                        disabled={form.values.csvFile}
                        saveFileOnForm={(_file) => form.setFieldValue("csvFile", _file)}/>
                </Grid.Col>
                <Grid.Col span={1} justify="center" align="center">
                    <IconTrash size={30}
                               className={'lessonForm__iconTrash'}
                               onClick={() => {
                                   form.setFieldValue("csvData", null)
                                   form.setFieldValue("csvFile", null)
                               }} m={'0 auto'}/>
                </Grid.Col>
                <Grid.Col span={12} justify="center" align="center">
                    <Textarea
                        label="Mensaje"
                        {...form.getInputProps('message')}
                        autosize
                        minRows={2}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button
                        type="submit"
                        loading={loading}
                    >
                        {loading
                            ? <Loader color="#fff" />
                            : "Iniciar campaña"
                        }
                    </Button>
                </Grid.Col>
            </Grid>
        </div>
    );
}

export default SalesBulkMessagesForm;
