import MainLayout from "../../layouts/MainLayout/MainLayout";
import {Route, Routes} from "react-router-dom";
import ViewLayout from "../../layouts/ViewLayout/ViewLayout";
import SalesHome from "../../features/Sales/SalesHome/SalesHome";
import SalesDiscounts from "../../features/Sales/SalesDiscounts/SalesDiscounts";
import SalesAcaUnitPriceContainer
    from "../../features/Sales/SalesAcademicUnitPrice/SalesAcaUnitPriceContainer/SalesAcaUnitPriceContainer";
import SalesPayments from "../../features/Sales/SalesPayments/SalesPaymentsContainer/SalesPayments";
import SalesBulkMessages from "../../features/Sales/SalesBulkMessages/SalesBulkMessages";

const Sales = () => {
    return (
        <MainLayout>
            <Routes>
                <Route
                    path='/'
                    element={
                        <ViewLayout
                            title='Ventas'
                            description='Aquí podras encontrar toda la información relacionada con el area de ventas de la plataforma.'
                            backBtnText='Ir a Ventas'
                            withBackButton
                        >
                            <SalesHome/>
                        </ViewLayout>
                    }
                />
                <Route
                    path='/descuentos'
                    element={
                        <ViewLayout
                            title='Descuentos'
                            description='Aquí podras crear y controlar los descuentos de la plataforma.'
                            backBtnText='Ir a Ventas'
                            withBackButton
                        >
                            <SalesDiscounts/>
                        </ViewLayout>
                    }
                />
                <Route
                path='/pagos-ingresados'
                element={
                    <ViewLayout
                        title='Gestión de inscripciones'
                        description='Aquí encontrarás todos los pedidos de inscripción así como la información de sus pagos asociados.'
                        backBtnText='Ir a Ventas'
                        withBackButton
                    >
                        <SalesPayments/>
                    </ViewLayout>
                }
            />
                < Route
                    path='/precios'
                    element={
                        <ViewLayout
                            title='Precios Unidades Académicas'
                            description='Modificar el monto en pesos y dolares de las unidades académicas existentes.'
                            backBtnText='Ir a Ventas'
                            withBackButton
                        >
                            <SalesAcaUnitPriceContainer/>
                        </ViewLayout>
                    }
                />
                < Route
                    path='/mensajes-en-masa'
                    element={
                        <ViewLayout
                            title='Mensajes automáticos'
                            description='Utilizar el BOT para enviar mensajes en masa a leads'
                            backBtnText='Ir a Ventas'
                            withBackButton
                        >
                            <SalesBulkMessages/>
                        </ViewLayout>
                    }
                />
            </Routes>
        </MainLayout>
    );
};
export default Sales;
