import React, {useContext, useEffect, useState} from 'react';
import {createColumnHelper} from "@tanstack/react-table";
import TanStackTable from "../../../components/Table/TanStackTable/TanStackTable";
import axios from "axios";
import ContentCreator from "../../../components/ContentCreator/ContentCreator";
import AuthContext from "../../../contexts/AuthContext";
import "./SalesBulkMessages.css";
import showNotification from "../../../utils/showNotification";
import {ERROR_HANDLER} from "../../../utils/error-handler";
import SalesBulkMessagesPreview from "./SalesBulkMessagesPreview/SalesBulkMessagesPreview";
import SalesBulkMessagesForm from "./SalesBulkMessagesForm/SalesBulkMessagesForm";

function SalesBulkMessages() {
    const {authToken} = useContext(AuthContext);

    const [campaignsData, setCampaignsData] = useState([]);
    // In this case, initial values will be used to replicate a campaign, not for editing
    const [initialCampaignValues, setInitialCampaignValues] = useState(null);
    const [displayForm, setDisplayForm] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [loading, setLoading] = useState(false);

    const axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`
        }
    };

    const createCampaign = async (values, config) => {
        const jsonData = {
            message: values.message,
            phones: values.csvData.map(row => row.phone),
        }

        return axios.post(
            `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/createMessageCampaign`,
            {data: jsonData},
            config
        );
    };

    // COLUMN DEFINITIONS
    const columnHelper = createColumnHelper();
    const columns = [

    ];

    // TABLE FUNCTIONS
    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            await createCampaign(values, axiosConfig);

            showNotification({
                color: "green",
                status: "success",
                title: "¡Excelente! Operación exitosa.",
                message: `La campaña ha sido creada / iniciada con éxito.`,
            });
            setUpdateData(!updateData);
            setDisplayForm(false);
        } catch (err) {
            showNotification({
                color: "red",
                status: "error",
                title: "Operación fallida.",
                message: `No se pudo crear / iniciar la campaña. Detalle: ${err.message || ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError
                }`,
                autoClose: 12000,
            });
        } finally {
            setLoading(false);
            setCampaignsData(null);
        }
    };

    // DATA INITIALIZATION
    const init = async () => {
        // Get campaigns
        // const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/campaigns`;
        // const campaignsInfo = await axios.get(url, axiosConfig);

        setCampaignsData([]);
    };

    useEffect(() => {
        init();
    }, [updateData]);

    return (
        <div className="salesBulkMessages__container">
            {displayForm && (
                <ContentCreator
                    title={"Crear campaña"}
                    submitFunc={(values) => handleSubmit(values)}
                    onCancel={() => {
                        setDisplayForm(false);
                        setInitialCampaignValues(null);
                    }}
                    initialValues={initialCampaignValues ?? null}
                    renderPreview={(_form) => (
                        <SalesBulkMessagesPreview form={_form.form}/>
                    )}
                    renderForm={(_form) => (
                        <SalesBulkMessagesForm initialValues={campaignsData} loading={loading} form={_form.form}/>
                    )}
                />
            )}
            {campaignsData && (
                <TanStackTable
                    data={campaignsData}
                    columns={columns}
                    // editFunc={handleEditCampaign}
                    displayForm={displayForm}
                    addButtonFunc={() => {
                        setDisplayForm(!displayForm);
                        if (displayForm) setInitialCampaignValues(null);
                    }}
                />
            )}
        </div>
    );
}

export default SalesBulkMessages;
