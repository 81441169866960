import React from 'react';
import './SalesBulkMessagesPreview.css';
import {Table} from "@mantine/core";

function SalesBulkMessagesPreview(props) {
    const {form} = props;

    return (
        <div className="salesBulkMessagesPreview__container">
            <h3 className="salesBulkMessagesPreview__title">Previsualización de datos</h3>
            {!form.values.csvData && (
                <p className="salesBulkMessagesPreview__noData">No hay datos cargados</p>
            )}
            <Table.ScrollContainer minWidth={800}>
                <Table verticalSpacing="xs">
                    <Table.Thead>
                        <Table.Tr>
                            {form.values.csvData && Object.keys(form.values.csvData[0]).map((header, index) => (
                                <Table.Th key={index}>{header}</Table.Th>
                            ))}
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {form.values.csvData && form.values.csvData.slice(0, 3).map((row, rowIndex) => (
                            <Table.Tr key={rowIndex}>
                                {Object.keys(row).map((header, index) => (
                                    <Table.Td key={index}>
                                        {row[header]}
                                    </Table.Td>
                                ))}
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        </div>
    );
}

export default SalesBulkMessagesPreview;