import React from "react";
import "./SalesHome.css";
import {IconDiscount, IconZoomMoney, IconSchool, IconRobotFace} from "@tabler/icons-react";
import ViewCard from "../../../components/ViewCard/ViewCard";
import SalesDashboardCarousel from "../SalesDashboardCarousel/SalesDashboardCarousel";

const SALES_ITEMS = [
    {
        title: "Precios unidades académicas",
        text: "Modificar el monto en pesos y dolares de las unidades académicas existentes.",
        href: "precios",
        icon: (
            <IconZoomMoney
                size={50}
                className="ViewCard--icon"
                color="var(--color-white)"
            />
        ),
    },
    {
        title: 'Descuentos',
        text: 'Generar descuentos para las unidades académicas.',
        href: 'descuentos',
        icon: < IconDiscount size={50} className='ViewCard--icon' color='var(--color-white)'/>,
    },
    {
        title: 'Inscripciones',
        text: 'Aquí encontrarás todas las solicitudes de inscripción a capacitaciones de FORVET.',
        href: 'pagos-ingresados',
        icon: < IconSchool size={50} className='ViewCard--icon' color='var(--color-white)' />,
     },
    {
        title: 'Mensajes automáticos',
        text: 'Aquí podrás utilizar el BOT para enviar mensajes automáticos a tus contactos.',
        href: 'mensajes-en-masa',
        icon: < IconRobotFace size={50} className='ViewCard--icon' color='var(--color-white)' />,
     }
];

const SalesHome = () => {
    return (
        <div className="salesHome-father-div">
            <div className="salesHome-grid-container">
                {SALES_ITEMS.map((item) => (
                    <ViewCard key={item.name} item={item}/>
                ))}
            </div>
            <div className="salesHome-dashboard-fatherDiv">
                <SalesDashboardCarousel/>
            </div>
        </div>
    );
};
export default SalesHome;
